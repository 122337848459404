<template>
  <div class="boxAll">
    <!-- 图标 标题 -->
    <div class="topTitle">
      <img
        src="@/assets/images/logo.png"
        alt="安泰乐"
        class="topLogo"
      > 网上生命纪念馆
      <!-- <span style="margin-left:20px;" @click="goGuidance">引导</span> -->
    </div>
    <!-- 纵向轮播 -->
    <van-swipe
      style="height: 100%;"
      vertical
    >
      <van-swipe-item class="vantItemOne">
        <div class="itemContainer">
          <div class="vantSwiperOneImg">
            <img
              src="@/assets/images/antail.png"
              alt="安泰乐"
            >
          </div>
          <div class="vantSwiperOneText">
            每一个生命
          </div>
          <div class="vantSwiperOneText">
            都值得被
            <span style="color:#BC882D;">
              纪念
            </span>
          </div>
        </div>
      </van-swipe-item>
      <!-- 第二张 -->
      <van-swipe-item class="vantItemTwo">
        <div class="itemContainer">
          <div class="vantSwiperTwoTextTop">
            安泰乐生命纪念馆
          </div>
          <div class="vantSwiperTwoTextMid">
            精神财富传承的载体
          </div>
          <div class="vantSwiperTwoTextUnd">
            <div>死亡不是人生的终点，遗忘才是</div>
            <div>为亲人建馆，随时记录，时刻触及</div>
            <div>纪念馆将承载一生的数据，世代相传</div>
          </div>
          <div
            class="vantSwiperBtn"
            @click="lookPreview"
          >
            观看演示
          </div>
         
        </div>
      </van-swipe-item>
      <!-- 第三张 -->
      <van-swipe-item class="vantItemThree">
        <div class="itemContainer">
          <div class="vantSwiperTwoTextTop">
            线上纪念
            <span style="color:#F6C943;">
              随时触及
            </span>
          </div>
          <div class="vantSwiperTwoTextUnd">
            <div>纪念再不限于特定的节日了</div>
            <div>纪念不用再奔波于两地之间</div>
            <div>有心，纪念，线上随时随地</div>
          </div>
          <div
            class="vantSwiperGoldBtn"
            @click="goCreateHall"
          >
            创建纪念馆
          </div>
          <div
            class="more"
            style="color:#fff;margin-top:20px;font-size:0.26rem;"
            @click="goGuidance"
          >更多创建方式</div>
        </div>
      </van-swipe-item>
    </van-swipe>
     <div class="iframBox" v-if="lookPreviewStatus">
            <div class="iframBoxClose" @click="close">×</div>
            <iframe
              src="https://go.yunzhongci.com/?timerid=289"
              frameborder="no"
              border="0"
              marginwidth="0"
              marginheight="0"
              scrolling="yes"
              allowtransparency="yes"
              class="iframBoxItem"
            ></iframe>
          </div>
  </div>
</template>
<script>
export default {
  name: "mindex",
  data() {
    return {
      lookPreviewStatus:false,
    };
  },
  methods: {
    //跳转到移动引导页面
    goGuidance() {
      this.$router.push("/guidance");
    },
    //跳转到创建纪念馆
    goCreateHall() {
      window.location.href = "http://h5.atl-life.com/";
    },
    //观看演示
    lookPreview() {
      this.lookPreviewStatus = true
      //  window.location.href="https://go.yunzhongci.com/?timerid=289"
    },
    close(){
      this.lookPreviewStatus = false
    }
  },
};
</script>
<style lang="scss" scoped>
.boxAll {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
}
.topTitle {
  position: fixed;
  z-index: 9;
  top: 0;
  width: 100%;
  height: 0.92rem;
  opacity: 1;
  background: #1a2230;
  font-size: 0.19rem;
  color: #b9782d;
  line-height: 0.92rem;
  display: flex;
  align-items: center;
}
.topLogo {
  width: 0.47rem;
  height: 0.51rem;
  margin-left: 0.29rem;
  margin-right: 0.1rem;
}
.vantItemOne {
  display: flex;
  align-items: center;
  justify-content: center;
  /*background-image: url('../../assets/images/anniversary@2x.png');*/
  // background-image: url('../../assets/images/mobileone.png');
  // background-image: url('../../assets/images/mbnewBg.jpg');
  // background-image: url('../../assets/images/newBg1.png');
  //  background-image: url('../../assets/images/newBg2.png');
  //  background-image: url('../../assets/images/newBg3.png');
  background-image: url("../../assets/images/newBg9.jpg");
  // background-image: url('../../assets/images/newBg3.png');
  //  background-image: url('../../assets/images/newBg3.png');
  //   background-image: url('../../assets/images/newBg3.png');

  background-size: 100% 100%;
}
.vantItemTwo {
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-image: url('../../assets/images/carrier@2x.png');*/
  //  background-image: url('../../assets/images/mobiletwo.png');
  background-image: url("../../assets/images/newBg5.jpg");
  background-size: 100% 100%;
}
.vantItemThree {
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("../../assets/images/touch@2x.png");
}
.itemContainer {
  width: 100%;
  height: 50%;
  /* background: cornsilk; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.vantSwiperOneImg {
  width: 2rem;
  height: 1.42rem;
  margin-bottom: 0.87rem;
}
.vantSwiperOneImg img {
  width: 100%;
  height: 100%;
}
.vantSwiperOneText {
  font-size: 0.52rem;
  font-weight: 500;
  text-align: center;
  color: #ffffff;
}
.vantSwiperBtn {
  width: 3.08rem;
  height: 0.88rem;
  opacity: 1;
  border: 0.02rem solid #ffffff;
  border-radius: 0.46rem;
  line-height: 0.88rem;
  text-align: center;
  font-size: 0.3rem;
  font-family: Source Han Sans CN Regular, Source Han Sans CN Regular-Regular;
  font-weight: 400;

  color: #ffffff;
}
.vantSwiperTwoTextTop {
  font-size: 0.44rem;
  font-family: Source Han Sans CN Medium, Source Han Sans CN Medium-Medium;
  font-weight: 500;
  text-align: center;
  color: #ffffff;
}
.vantSwiperTwoTextMid {
  font-size: 0.44rem;
  font-family: Source Han Sans CN Medium, Source Han Sans CN Medium-Medium;
  font-weight: 500;
  text-align: center;
  color: #f6c943;
  background-image: -webkit-linear-gradient(bottom, #b8832d, #f6c943);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.vantSwiperTwoTextUnd {
  margin-top: 0.3rem;
  margin-bottom: 0.5rem;
  font-size: 0.26rem;
  font-family: Source Han Sans CN Regular, Source Han Sans CN Regular-Regular;
  font-weight: 400;
  text-align: left;
  // color: #4f4e4e;
  // color: #fff;
  line-height: 0.5rem;
  letter-spacing: 0.03rem;
  text-align: center;
}
.vantSwiperTwoTextUnd div {
  background-image: -webkit-linear-gradient(bottom, #746262, #a77e7e);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  // color: #fff;
}
.vantSwiperGoldBtn {
  width: 3.94rem;
  height: 1.01rem;
  opacity: 1;
  background: #bb862e;
  border-radius: 0.51rem;
  text-align: center;
  font-size: 0.38rem;
  font-family: Source Han Sans CN Regular, Source Han Sans CN Regular-Regular;
  font-weight: 400;
  color: #ffffff;
  line-height: 1.01rem;
}
.iframBox {
  width: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 10;
  background: #fff;
}
.iframBoxItem {
  width: 100%;
  height: 100%;
  z-index: 11;
}
.iframBoxClose{
  position: absolute;
  z-index: 12;
  top:0.5rem;
  width: 0.5rem;
  height: 0.5rem;
  left: 0.5rem;
  background: #ccc;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>